@import "settings";

* {
  padding: 0;
  margin: 0;
}

body {
    font-family: 'Montserrat', sans-serif;
    background-color: $color-bg;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}


.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &-logo {
    width: 350px;
    margin-bottom: 50px;
  }
  &-contact {
    a {
      margin: 0 30px;
      font-weight: 600;
      color: #000;
      text-decoration: none;
      transition: all 0.3s;
      &:hover {
        color: #015799;
      }
      i {
        margin-right: 5px;
      }
    }
  }
}
